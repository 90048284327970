<template>
    <page :loading="loading">
        <div slot="head" class="flex-col">
            <div class="flex-item flex-fill" style="text-align: center;">
            </div>
            <div class="flex-item flex-right">
                <el-button type="primary" style="width:100px" @click="table.form = {},$refs['page-dialog'].open()">添加</el-button>
            </div>
        </div>

        <page-table :datas="table.data" :page="table.search._page" :size="table.search._size" :total="table.search._count" @change="LoadDatas">
            <el-table-column prop="id"                             label="id" align="center" fixed="left"  width="120" />
            <el-table-column prop='name'                           label='名称' align='center' />
            <el-table-column prop='key'                            label='key' align='center' />
            <el-table-column prop='created_at'                     label='创建时间' align='center' />
            <el-table-column prop='updated_at'                     label='更新时间' align='center' />
            <el-table-column label="操作" align="center" fixed="right">
                <template slot-scope="scope">
                    <el-button @click="table.form = Object.assign({},scope.row),$refs['page-dialog'].open()" type="text" size="small">编辑</el-button>
                </template>
            </el-table-column>
        </page-table>

        <page-dialog ref="page-dialog" :loading="loading">
            <el-form ref="form">
                <el-form-item label="名称">
                    <el-input v-model="table.form.name" placeholder="请输入名称" />
                </el-form-item>
                <el-form-item label="key">
                    <el-input :disabled="table.form.id ? true : false" v-model="table.form.key" placeholder="请输入key" />
                </el-form-item>
				<el-form-item label="状态">
				    <el-select :disabled="table.form.id ? true : false" v-model="table.form.type" placeholder="设置类型">
				    	<el-option label="普通" :value="0" />
				    	<el-option label="富文本" :value="1" />
				    </el-select>
				</el-form-item>
                <el-form-item v-if="table.form.type != null" label="value" class="block">
                    <el-input v-if="table.form.type	== 0" v-model="table.form.value"  placeholder="请输入value" />
					<page-editor v-if="table.form.type	== 1" v-model="table.form.value" />
                </el-form-item>
            </el-form>
            <div slot="footer">
                <el-button type="primary" @click="ActionSave(table.form)">保存</el-button>
            </div>
        </page-dialog>
    </page>
</template>
<script>
export default {
    data(){
        return {
            loading:false,
            table:{
                data:[],
                form:{},
                search:{
                    _page:1,
                    _size:20,
                    _count:0,
                }
            }
        }
    },
    mounted() {
        this.LoadDatas();
    },
    methods: {
        /**
         * 获取数据列表
         * @param page 分页-当前页
         * @param size 分页-每页数量
         * **/
        LoadDatas(page,size){
            page && (this.table.search._page = page),size && (this.table.search._size = size),this.loading = true;
            this.$get('sys-settings',this.table.search).then(res=>
            {
                this.table.data = res.data , this.table.search._count = res.extra.pageInfo.count , this.loading = false;
            }).catch(err=>
            {
                this.$notify.error({message: err || '网络请求错误'}),this.loading = false;
            });
        },

        /**
         * 保存
         * @param item 要保存的数据
         * **/
        ActionSave(item){
            this.loading = true;
            this.$save('sys-settings',item).then(res=>
            {
                this.loading = false , this.LoadDatas(),this.$notify({message: res.msg || '操作成功'}) , this.$refs['page-dialog'].close();
            }).catch(err=>
            {
                this.loading = false , this.$notify.error({message: err || '网络请求错误'});
            });
        },

        /**
         * 删除
         * @param item 要删除的数据
         * **/
        ActionDel(item){
            if( item.id ){
                this.loading = true;
                this.$confirm('此操作将永久删除, 是否继续?', '提示', {confirmButtonText: '确定',cancelButtonText: '取消',type: 'warning'}).then(() => {
                    this.$del('sys-settings/'+item.id).then(res=>{
                        this.loading = false , this.LoadDatas() , this.$notify({message: res.msg || '操作成功'});
                    }).catch(err=>{
                        this.loading = false , this.$notify.error({message:err||'删除失败'});
                    })
                }).catch(()=>{
                    this.loading = false;
                })
            }
        }
    },
}
</script>